// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-wrapper {
    border: 1px solid var(--border-clr);
    border-radius: 6px;
}

.search {
    display: inline-flex;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 6px;
    outline: 2px solid transparent;
    outline-offset: 4px;
    transition: all 0.1s ease-in-out;
    width: 100%;
}

.search__icon {
    width: 18px;
    height: 18px;
}


.search__icon {
    margin: 0 10px;
}

.search input {
    border: none;
    height: 40px;
    font-family: Inter;
    font-size: 1rem;
    width: 100%;
    background-color: transparent;
}

.search input::placeholder {
    font-size: 1rem;
}

.search input:focus {
    outline: none;
}

.search:focus-within {
    outline: 2px solid #95afec;
    outline-offset: 2px;
    border: 2px solid #2684ea;
}

.icon-button {
    height: 25px;
    width: 25px;
    transition: all 0.3s ease;
}

.icon-button:hover {
    background-color: #edf2f7;
    border-radius: 6px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Search.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,6BAA6B;IAC7B,kBAAkB;IAClB,8BAA8B;IAC9B,mBAAmB;IACnB,gCAAgC;IAChC,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".search-wrapper {\n    border: 1px solid var(--border-clr);\n    border-radius: 6px;\n}\n\n.search {\n    display: inline-flex;\n    align-items: center;\n    border: 2px solid transparent;\n    border-radius: 6px;\n    outline: 2px solid transparent;\n    outline-offset: 4px;\n    transition: all 0.1s ease-in-out;\n    width: 100%;\n}\n\n.search__icon {\n    width: 18px;\n    height: 18px;\n}\n\n\n.search__icon {\n    margin: 0 10px;\n}\n\n.search input {\n    border: none;\n    height: 40px;\n    font-family: Inter;\n    font-size: 1rem;\n    width: 100%;\n    background-color: transparent;\n}\n\n.search input::placeholder {\n    font-size: 1rem;\n}\n\n.search input:focus {\n    outline: none;\n}\n\n.search:focus-within {\n    outline: 2px solid #95afec;\n    outline-offset: 2px;\n    border: 2px solid #2684ea;\n}\n\n.icon-button {\n    height: 25px;\n    width: 25px;\n    transition: all 0.3s ease;\n}\n\n.icon-button:hover {\n    background-color: #edf2f7;\n    border-radius: 6px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
