// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ValueAdjuster_label__DfhYM {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-family: InterMedium;
}

.ValueAdjuster_input__gCAhF {
    width: 100%;
    padding: 0.25rem 0.75rem;
    border: var(--border-radius-lg) solid var(--border-clr);
    border-radius: 4px;
    font-size: 1rem;
    line-height: 1.5;
    color: #4a5568;
    background-color: #fff;
}

.ValueAdjuster_input__gCAhF:hover,
.ValueAdjuster_input__gCAhF:focus {
    border: 1px solid var(--border-clr-hover);
}

.ValueAdjuster_input__gCAhF:first-of-type {
    width: 70%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.ValueAdjuster_input__gCAhF:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 30%;
}

.ValueAdjuster_button__HLfYH {
    padding: .375rem .75rem;
    border: var(--border-radius-lg) solid var(--border-clr);
    border-radius: 0;
    border-right: none;
}`, "",{"version":3,"sources":["webpack://./src/components/form/inputs/ValueAdjuster.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;IACI,WAAW;IACX,wBAAwB;IACxB,uDAAuD;IACvD,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,sBAAsB;AAC1B;;AAEA;;IAEI,yCAAyC;AAC7C;;AAEA;IACI,UAAU;IACV,0BAA0B;IAC1B,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,4BAA4B;IAC5B,UAAU;AACd;;AAEA;IACI,uBAAuB;IACvB,uDAAuD;IACvD,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".label {\n    margin-bottom: 0.25rem;\n    font-size: 0.875rem;\n    font-family: InterMedium;\n}\n\n.input {\n    width: 100%;\n    padding: 0.25rem 0.75rem;\n    border: var(--border-radius-lg) solid var(--border-clr);\n    border-radius: 4px;\n    font-size: 1rem;\n    line-height: 1.5;\n    color: #4a5568;\n    background-color: #fff;\n}\n\n.input:hover,\n.input:focus {\n    border: 1px solid var(--border-clr-hover);\n}\n\n.input:first-of-type {\n    width: 70%;\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n    border-right: none;\n}\n\n.input:last-of-type {\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n    width: 30%;\n}\n\n.button {\n    padding: .375rem .75rem;\n    border: var(--border-radius-lg) solid var(--border-clr);\n    border-radius: 0;\n    border-right: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `ValueAdjuster_label__DfhYM`,
	"input": `ValueAdjuster_input__gCAhF`,
	"button": `ValueAdjuster_button__HLfYH`
};
export default ___CSS_LOADER_EXPORT___;
