// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 740px) {
    .CreateAgent_modal__form__row__RpIEF {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/admin/CreateAgent.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":["@media (max-width: 740px) {\n    .modal__form__row {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal__form__row": `CreateAgent_modal__form__row__RpIEF`
};
export default ___CSS_LOADER_EXPORT___;
