// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading_gif
{
    width:100px;
    height:100px;
    background:transparent;
    opacity:1;
}

.loading_gif_container
{
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    flex-direction:row;
}`, "",{"version":3,"sources":["webpack://./src/components/LoadingGif.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,SAAS;AACb;;AAEA;;IAEI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,oBAAoB;IACpB,kBAAkB;AACtB","sourcesContent":[".loading_gif\r\n{\r\n    width:100px;\r\n    height:100px;\r\n    background:transparent;\r\n    opacity:1;\r\n}\r\n\r\n.loading_gif_container\r\n{\r\n    display:flex;\r\n    justify-content:center;\r\n    align-items:center;\r\n    align-content:center;\r\n    flex-direction:row;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
