// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditAgent_footer__qPcCq {
    padding-top: 1rem;
    gap: 1rem;
}

@media (max-width: 470px) {
    .EditAgent_footer__qPcCq {
        align-items: flex-start;
        flex-direction: column;
    }

    .EditAgent_primaryButtons__bDnD2,
    .EditAgent_primaryButtons__bDnD2>* {
        width: 100%;
    }

    .EditAgent_multiSelect__tEFis {
        font-size: 10px;
    }

    .EditAgent_buttons-row__voLIv {
        flex-direction: column;
    }
}

@media (max-width: 740px) {
    .EditAgent_modal__form__row__lGcwU {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/admin/EditAgent.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI;QACI,uBAAuB;QACvB,sBAAsB;IAC1B;;IAEA;;QAEI,WAAW;IACf;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,sBAAsB;IAC1B;AACJ;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".footer {\n    padding-top: 1rem;\n    gap: 1rem;\n}\n\n@media (max-width: 470px) {\n    .footer {\n        align-items: flex-start;\n        flex-direction: column;\n    }\n\n    .primaryButtons,\n    .primaryButtons>* {\n        width: 100%;\n    }\n\n    .multiSelect {\n        font-size: 10px;\n    }\n\n    .buttons-row {\n        flex-direction: column;\n    }\n}\n\n@media (max-width: 740px) {\n    .modal__form__row {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `EditAgent_footer__qPcCq`,
	"primaryButtons": `EditAgent_primaryButtons__bDnD2`,
	"multiSelect": `EditAgent_multiSelect__tEFis`,
	"buttons-row": `EditAgent_buttons-row__voLIv`,
	"modal__form__row": `EditAgent_modal__form__row__lGcwU`
};
export default ___CSS_LOADER_EXPORT___;
