// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=file]::file-selector-button {
    background-color: var(--background-color);
    color: var(--color, #fff);
    padding: 0.44rem 1.25rem;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    font-family: InterMedium;
    transition: background-color 0.1s ease-in-out;
    border: 1px solid var(--border-color);
    align-items: center;
    justify-content: center;
    width: max-content;
    cursor: pointer;
}

input[type=file]::file-selector-button:hover {
    background-color: var(--background-color-hover);
}

input[type=file]::file-selector-button:active {
    background-color: var(--background-color-active);
}`, "",{"version":3,"sources":["webpack://./src/components/form/inputs/FileInput.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,yBAAyB;IACzB,wBAAwB;IACxB,kBAAkB;IAClB,qBAAqB;IACrB,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;IACvB,wBAAwB;IACxB,6CAA6C;IAC7C,qCAAqC;IACrC,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,gDAAgD;AACpD","sourcesContent":["input[type=file]::file-selector-button {\n    background-color: var(--background-color);\n    color: var(--color, #fff);\n    padding: 0.44rem 1.25rem;\n    text-align: center;\n    text-decoration: none;\n    display: inline-flex;\n    font-size: 0.875rem;\n    border-radius: 0.375rem;\n    font-family: InterMedium;\n    transition: background-color 0.1s ease-in-out;\n    border: 1px solid var(--border-color);\n    align-items: center;\n    justify-content: center;\n    width: max-content;\n    cursor: pointer;\n}\n\ninput[type=file]::file-selector-button:hover {\n    background-color: var(--background-color-hover);\n}\n\ninput[type=file]::file-selector-button:active {\n    background-color: var(--background-color-active);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
