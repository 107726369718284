// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usage-stats {
    display: flex;
    gap: 1.25rem;
    flex-direction: column;
}

.flex {
    display: flex;
}

.usage-stats .flex {
    gap: 1.25rem;
}

.usage-stats__item {
    flex: 1 1;
    display: flex;
    padding: 1rem;
    flex-direction: row;
    text-align: center;
    color: white;
    background-color: #F9FAFB;
    padding: 1.5rem;
    border-radius: 0.75rem;
    align-items: center;
    border: 1px solid #d2d2d4;
}

.usage-stats__item__label {
    color: #4A5568;
    font-size: 0.875rem;
    font-family: InterMedium;
}

.usage-stats__item__value {
    color: #1a202c;
    margin-left: auto;
}

.usage-stats__item__value span {
    font-size: 1.875rem;
    font-weight: 600;
}


@media (max-width: 750px) {

    .usage-stats .flex {
        flex-direction: column;
    }
}

.agent_name_box
{
    font-size: 1.875rem;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/agent/UsageStats.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,SAAa;IACb,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;;AAGA;;IAEI;QACI,sBAAsB;IAC1B;AACJ;;AAEA;;IAEI,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".usage-stats {\n    display: flex;\n    gap: 1.25rem;\n    flex-direction: column;\n}\n\n.flex {\n    display: flex;\n}\n\n.usage-stats .flex {\n    gap: 1.25rem;\n}\n\n.usage-stats__item {\n    flex: 1 1 0px;\n    display: flex;\n    padding: 1rem;\n    flex-direction: row;\n    text-align: center;\n    color: white;\n    background-color: #F9FAFB;\n    padding: 1.5rem;\n    border-radius: 0.75rem;\n    align-items: center;\n    border: 1px solid #d2d2d4;\n}\n\n.usage-stats__item__label {\n    color: #4A5568;\n    font-size: 0.875rem;\n    font-family: InterMedium;\n}\n\n.usage-stats__item__value {\n    color: #1a202c;\n    margin-left: auto;\n}\n\n.usage-stats__item__value span {\n    font-size: 1.875rem;\n    font-weight: 600;\n}\n\n\n@media (max-width: 750px) {\n\n    .usage-stats .flex {\n        flex-direction: column;\n    }\n}\n\n.agent_name_box\n{\n    font-size: 1.875rem;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
