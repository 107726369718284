// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
    width: 100%;
    height: 6px;
    background-color: #e2e8f0;
    border-radius: 50px;
    margin: 10px 0;
    position: relative;
    display: flex;
    align-items: center;
}

.progress-bar__filler--expired {
    height: 6px;
    border-radius: 50px;
    background-color: #dd6b20;
}

.progress-bar__filler--limited {
    height: 6px;
    border-radius: 50px;
    background-color: #e53e3e;
}

.progress-bar__filler--active {
    height: 6px;
    border-radius: 50px;
    background-color: #396fe4;
}

.progress-bar__filler--anonym {
    height: 6px;
    border-radius: 50px;
    background-color: #571e7b;
}

.progress-bar__filler--disable {
    height: 6px;
    border-radius: 50px;
    background-color: #1a202c;
}

.progress-bar__text {
    margin-top: -5px;
    font-size: 0.75rem;
    color: #4a5568;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    font-family: InterMedium;
}

/* @media (max-width: 690px) {

    .progress-bar,
    .progress-bar__text__total-data {
        display: none;
    }
} */`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,wBAAwB;AAC5B;;AAEA;;;;;;GAMG","sourcesContent":[".progress-bar {\n    width: 100%;\n    height: 6px;\n    background-color: #e2e8f0;\n    border-radius: 50px;\n    margin: 10px 0;\n    position: relative;\n    display: flex;\n    align-items: center;\n}\n\n.progress-bar__filler--expired {\n    height: 6px;\n    border-radius: 50px;\n    background-color: #dd6b20;\n}\n\n.progress-bar__filler--limited {\n    height: 6px;\n    border-radius: 50px;\n    background-color: #e53e3e;\n}\n\n.progress-bar__filler--active {\n    height: 6px;\n    border-radius: 50px;\n    background-color: #396fe4;\n}\n\n.progress-bar__filler--anonym {\n    height: 6px;\n    border-radius: 50px;\n    background-color: #571e7b;\n}\n\n.progress-bar__filler--disable {\n    height: 6px;\n    border-radius: 50px;\n    background-color: #1a202c;\n}\n\n.progress-bar__text {\n    margin-top: -5px;\n    font-size: 0.75rem;\n    color: #4a5568;\n    font-weight: 500;\n    display: flex;\n    justify-content: space-between;\n    gap: 0.5rem;\n    font-family: InterMedium;\n}\n\n/* @media (max-width: 690px) {\n\n    .progress-bar,\n    .progress-bar__text__total-data {\n        display: none;\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
