// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-section {
    width: 100%;
}

.subscription-section__buttons {
    display: flex;
    justify-content: space-between;
}

.subscription-section__button {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    color: #1a202c;
    position: relative;
}

.subscription-section__button svg {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/agent/SubscriptionActions.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".subscription-section {\n    width: 100%;\n}\n\n.subscription-section__buttons {\n    display: flex;\n    justify-content: space-between;\n}\n\n.subscription-section__button {\n    background-color: transparent;\n    border: none;\n    border-radius: 4px;\n    color: #1a202c;\n    position: relative;\n}\n\n.subscription-section__button svg {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
