// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.container {
    padding: 1rem 0;
}

.search-wrapper {
    width: 25rem;
}

.gap-16 {
    gap: 1rem;
}

.refresh-icon svg {
    transition: transform 0.3s linear;
    width: 15px;
    height: 15px;
}

.refresh-icon svg:hover {
    animation: rotate 1s infinite linear;
    transform: rotate(180deg);
}

.users-page__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1rem;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 900px) {
    .column-reverse {
        flex-direction: column-reverse;
    }
}

@media (max-width: 750px) {
    .items-end {
        align-items: flex-end;
    }

    .search-wrapper {
        width: 100%;
    }
}

@media (max-width: 585px) {
    .users-page__footer {
        flex-direction: column-reverse;
        gap: 1rem;
        align-items: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/agent/UsersPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,iCAAiC;IACjC,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,8BAA8B;IAClC;AACJ;;AAEA;IACI;QACI,qBAAqB;IACzB;;IAEA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,8BAA8B;QAC9B,SAAS;QACT,mBAAmB;IACvB;AACJ","sourcesContent":[".flex {\n    display: flex;\n}\n\n.items-center {\n    align-items: center;\n}\n\n.justify-between {\n    justify-content: space-between;\n}\n\n.container {\n    padding: 1rem 0;\n}\n\n.search-wrapper {\n    width: 25rem;\n}\n\n.gap-16 {\n    gap: 1rem;\n}\n\n.refresh-icon svg {\n    transition: transform 0.3s linear;\n    width: 15px;\n    height: 15px;\n}\n\n.refresh-icon svg:hover {\n    animation: rotate 1s infinite linear;\n    transform: rotate(180deg);\n}\n\n.users-page__footer {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    margin-top: 1rem;\n}\n\n@keyframes rotate {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n@media (max-width: 900px) {\n    .column-reverse {\n        flex-direction: column-reverse;\n    }\n}\n\n@media (max-width: 750px) {\n    .items-end {\n        align-items: flex-end;\n    }\n\n    .search-wrapper {\n        width: 100%;\n    }\n}\n\n@media (max-width: 585px) {\n    .users-page__footer {\n        flex-direction: column-reverse;\n        gap: 1rem;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
