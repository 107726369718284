// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.links__slider {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.qr-code__links {
    display: flex;
    max-width: 300px;
    gap: 1rem;
    overflow-x: hidden;
}

.arrow-left svg,
.arrow-right svg {
    width: 20px;
    height: 20px;
}

.inner {
    display: flex;
    gap: 1rem;
}

.arrow-left svg,
.arrow-right svg {
    flex: 0 0 40px;
}

.qr-code__modal .modal__content {
    position: fixed;
    width: -moz-fit-content;
    width: fit-content;
}

@media (max-width: 900px) {
    .qr-code__main {
        flex-direction: column;
    }

    .qr-code__modal .modal__content {
        top: 10px;
        width: 1;
    }
}

.qrcode_navigation_handler
{
display:flex;
justify-content:center;
align-items:center;
align-content:center;
flex-direction:row;
gap:10px;
}`, "",{"version":3,"sources":["webpack://./src/components/QRCode.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,SAAS;IACT,kBAAkB;AACtB;;AAEA;;IAEI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;;IAEI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,SAAS;QACT,QAAQ;IACZ;AACJ;;AAEA;;AAEA,YAAY;AACZ,sBAAsB;AACtB,kBAAkB;AAClB,oBAAoB;AACpB,kBAAkB;AAClB,QAAQ;AACR","sourcesContent":[".links__slider {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n}\n\n.qr-code__links {\n    display: flex;\n    max-width: 300px;\n    gap: 1rem;\n    overflow-x: hidden;\n}\n\n.arrow-left svg,\n.arrow-right svg {\n    width: 20px;\n    height: 20px;\n}\n\n.inner {\n    display: flex;\n    gap: 1rem;\n}\n\n.arrow-left svg,\n.arrow-right svg {\n    flex: 0 0 40px;\n}\n\n.qr-code__modal .modal__content {\n    position: fixed;\n    width: fit-content;\n}\n\n@media (max-width: 900px) {\n    .qr-code__main {\n        flex-direction: column;\n    }\n\n    .qr-code__modal .modal__content {\n        top: 10px;\n        width: 1;\n    }\n}\n\n.qrcode_navigation_handler\n{\ndisplay:flex;\njustify-content:center;\nalign-items:center;\nalign-content:center;\nflex-direction:row;\ngap:10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
