// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leading-icon {
    margin-right: 0.9rem;
    background-color: #4d7ee766;
    border-radius: 0.5rem;
    padding: 4px 4px 0 4px;
}

.leading-icon svg,
img {
    background-color: #4D7DE7;
    color: white;
    opacity: 0.6;
    border-radius: 0.5rem;
    width: 38px;
    height: 38px;
    padding: 7px;
    stroke-width: 1.5;
}`, "",{"version":3,"sources":["webpack://./src/components/LeadingIcon.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,2BAA2B;IAC3B,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;;IAEI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".leading-icon {\n    margin-right: 0.9rem;\n    background-color: #4d7ee766;\n    border-radius: 0.5rem;\n    padding: 4px 4px 0 4px;\n}\n\n.leading-icon svg,\nimg {\n    background-color: #4D7DE7;\n    color: white;\n    opacity: 0.6;\n    border-radius: 0.5rem;\n    width: 38px;\n    height: 38px;\n    padding: 7px;\n    stroke-width: 1.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
