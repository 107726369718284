// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: "Inter";
    font-weight: 600;
}

.pagination-left-btn svg,
.pagination-right-btn svg {
    width: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;;IAEI,WAAW;AACf","sourcesContent":[".pagination {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    font-family: \"Inter\";\n    font-weight: 600;\n}\n\n.pagination-left-btn svg,\n.pagination-right-btn svg {\n    width: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
