// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    .AdminHomePage_charts-subinfo__diWIa {
        flex-direction: column;
        margin-left: 2rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/AdminHomePage.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;QACtB,iBAAiB;IACrB;AACJ","sourcesContent":["@media (max-width: 768px) {\n    .charts-subinfo {\n        flex-direction: column;\n        margin-left: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"charts-subinfo": `AdminHomePage_charts-subinfo__diWIa`
};
export default ___CSS_LOADER_EXPORT___;
