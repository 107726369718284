// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-row:hover {
    background-color: transparent !important;
    cursor: default;
}

.empty-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem 1.25rem;
}

.empty-table>svg {
    max-width: 200px;
    max-height: 200px;
}

.empty-table>h6 {
    font-family: InterMedium;
    font-size: 1rem;
    font-weight: 400;
    color: var(--dark-clr-200);
}

.empty-table>button {
    text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/EmptyTable.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;IACxB,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".empty-row:hover {\n    background-color: transparent !important;\n    cursor: default;\n}\n\n.empty-table {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 1rem;\n    padding: 2rem 1.25rem;\n}\n\n.empty-table>svg {\n    max-width: 200px;\n    max-height: 200px;\n}\n\n.empty-table>h6 {\n    font-family: InterMedium;\n    font-size: 1rem;\n    font-weight: 400;\n    color: var(--dark-clr-200);\n}\n\n.empty-table>button {\n    text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
