// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    background-color: #1a202ce6;
    font-size: 0.875rem;
    font-family: InterMedium;
    color: var(--gray-clr-100);
    padding: 7px 10px;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 15px;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.tooltip.tooltip-fade-in {
    animation: fade-in .3s forwards;
}

.tooltip.tooltip-fade-out {
    animation: fade-out .3s backwards;
}`, "",{"version":3,"sources":["webpack://./src/components/Tooltip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,kBAAkB;IAClB,2BAA2B;IAC3B,mBAAmB;IACnB,wBAAwB;IACxB,0BAA0B;IAC1B,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".tooltip {\n    position: absolute;\n    z-index: 1;\n    bottom: 100%;\n    left: 50%;\n    transform: translateX(-50%);\n    width: max-content;\n    background-color: #1a202ce6;\n    font-size: 0.875rem;\n    font-family: InterMedium;\n    color: var(--gray-clr-100);\n    padding: 7px 10px;\n    border-radius: 6px;\n    text-align: center;\n    margin-bottom: 15px;\n}\n\n@keyframes fade-in {\n    from {\n        opacity: 0;\n    }\n\n    to {\n        opacity: 1;\n    }\n}\n\n@keyframes fade-out {\n    from {\n        opacity: 1;\n    }\n\n    to {\n        opacity: 0;\n    }\n}\n\n.tooltip.tooltip-fade-in {\n    animation: fade-in .3s forwards;\n}\n\n.tooltip.tooltip-fade-out {\n    animation: fade-out .3s backwards;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
