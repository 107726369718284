// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    margin-bottom: 1.5rem;
}

.navbar__links {
    display: flex;
    font-family: InterMedium;
    font-size: 1rem;
    margin-bottom: .25rem;
}

.navbar__links li {
    padding: 0.5rem 0.5rem;
    position: relative;
}

.navbar__link {
    color: var(--dark-clr-200);
    opacity: 0.7;
    transition: all 0.3s ease;
}

.navbar__line {
    height: 2px;
    width: 0;
    left: 0;
    background-color: var(--primary-clr-100);
    position: absolute;
    transition: width 0.3s ease, transform 0.3s ease;
    transform-origin: left;
}

.navbar__links li:hover~.navbar__line,
.navbar__links li:focus~.navbar__line {
    width: 100%;
    transform: scaleX(1);
}

.navbar__background-line {
    height: 2px;
    background-color: var(--gray-clr-200);
    border-radius: 10px;
}

.navbar__links li:hover .navbar__link,
.navbar__links li:focus .navbar__link,
.navbar__links li:active .navbar__link {
    opacity: 1;
}

.navbar__link.active {
    width: 100%;
    opacity: 1;
    transform: scaleX(1);
    color: var(--primary-clr-200);
}

@media (max-width: 420px) {
    .navbar__links {
        font-size: 12px;
    }

    .navbar__links li {
        padding: 0.5rem 0.25rem;
        position: relative;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,wBAAwB;IACxB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,QAAQ;IACR,OAAO;IACP,wCAAwC;IACxC,kBAAkB;IAClB,gDAAgD;IAChD,sBAAsB;AAC1B;;AAEA;;IAEI,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;;;IAGI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,UAAU;IACV,oBAAoB;IACpB,6BAA6B;AACjC;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,uBAAuB;QACvB,kBAAkB;IACtB;AACJ","sourcesContent":[".navbar {\n    margin-bottom: 1.5rem;\n}\n\n.navbar__links {\n    display: flex;\n    font-family: InterMedium;\n    font-size: 1rem;\n    margin-bottom: .25rem;\n}\n\n.navbar__links li {\n    padding: 0.5rem 0.5rem;\n    position: relative;\n}\n\n.navbar__link {\n    color: var(--dark-clr-200);\n    opacity: 0.7;\n    transition: all 0.3s ease;\n}\n\n.navbar__line {\n    height: 2px;\n    width: 0;\n    left: 0;\n    background-color: var(--primary-clr-100);\n    position: absolute;\n    transition: width 0.3s ease, transform 0.3s ease;\n    transform-origin: left;\n}\n\n.navbar__links li:hover~.navbar__line,\n.navbar__links li:focus~.navbar__line {\n    width: 100%;\n    transform: scaleX(1);\n}\n\n.navbar__background-line {\n    height: 2px;\n    background-color: var(--gray-clr-200);\n    border-radius: 10px;\n}\n\n.navbar__links li:hover .navbar__link,\n.navbar__links li:focus .navbar__link,\n.navbar__links li:active .navbar__link {\n    opacity: 1;\n}\n\n.navbar__link.active {\n    width: 100%;\n    opacity: 1;\n    transform: scaleX(1);\n    color: var(--primary-clr-200);\n}\n\n@media (max-width: 420px) {\n    .navbar__links {\n        font-size: 12px;\n    }\n\n    .navbar__links li {\n        padding: 0.5rem 0.25rem;\n        position: relative;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
