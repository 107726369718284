// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimerBar_timer-bar__Hhj5c {
    width: 100%;
    height: 6px;
    background-color: #e2e8f0;
    border-radius: 50px;
    margin: 10px 0;
    position: relative;
    display: flex;
    align-items: center;
}

.TimerBar_timer-bar__filler__-6fXH {
    animation-duration: --duration;
    height: 6px;
    border-radius: 50px;
    background-color: #396fe4;
    animation: TimerBar_timer__JVf3F calc(var(--duration) * 1s) linear 0.2s forwards;
    transform-origin: left center;
}

@keyframes TimerBar_timer__JVf3F {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/TimerBar.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,WAAW;IACX,mBAAmB;IACnB,yBAAyB;IACzB,gFAAgE;IAChE,6BAA6B;AACjC;;AAEA;IACI;QACI,SAAS;IACb;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".timer-bar {\n    width: 100%;\n    height: 6px;\n    background-color: #e2e8f0;\n    border-radius: 50px;\n    margin: 10px 0;\n    position: relative;\n    display: flex;\n    align-items: center;\n}\n\n.timer-bar__filler {\n    animation-duration: --duration;\n    height: 6px;\n    border-radius: 50px;\n    background-color: #396fe4;\n    animation: timer calc(var(--duration) * 1s) linear 0.2s forwards;\n    transform-origin: left center;\n}\n\n@keyframes timer {\n    0% {\n        width: 0%;\n    }\n\n    100% {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer-bar": `TimerBar_timer-bar__Hhj5c`,
	"timer-bar__filler": `TimerBar_timer-bar__filler__-6fXH`,
	"timer": `TimerBar_timer__JVf3F`
};
export default ___CSS_LOADER_EXPORT___;
