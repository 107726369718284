// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lo_button
{
    position:absolute;
    top:20px;
    right:25px;
    border:none;
}`, "",{"version":3,"sources":["webpack://./src/components/UniversalLogout.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,QAAQ;IACR,UAAU;IACV,WAAW;AACf","sourcesContent":[".lo_button\r\n{\r\n    position:absolute;\r\n    top:20px;\r\n    right:25px;\r\n    border:none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
