// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditPanel_footer__fgYIi {
    padding-top: 1rem;
    gap: 1rem;
}

@media (max-width: 410px) {
    .EditPanel_footer__fgYIi {
        align-items: flex-start;
        flex-direction: column;
    }

    .EditPanel_primaryButtons__L1y7d,
    .EditPanel_primaryButtons__L1y7d>* {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/admin/EditPanel.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI;QACI,uBAAuB;QACvB,sBAAsB;IAC1B;;IAEA;;QAEI,WAAW;IACf;AACJ","sourcesContent":[".footer {\n    padding-top: 1rem;\n    gap: 1rem;\n}\n\n@media (max-width: 410px) {\n    .footer {\n        align-items: flex-start;\n        flex-direction: column;\n    }\n\n    .primaryButtons,\n    .primaryButtons>* {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `EditPanel_footer__fgYIi`,
	"primaryButtons": `EditPanel_primaryButtons__L1y7d`
};
export default ___CSS_LOADER_EXPORT___;
